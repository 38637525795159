//Reference for language codes: https://stackoverflow.com/questions/5580876/navigator-language-list-of-all-languages

export interface translations {
    //StartScreen
    greeting: string;
    initialCatchPhrase: string;
    yourNamePlaceholder: string;
    yourEmailPlaceholder: string;
    startChatButton: string;
    continueChatButton: string;
    provideDetails: string;
    termsAndConditions1: string;
    termsAndConditions2: string;
    termsAndConditionsLink: string;
    madeIn: string;
    //ChatScreen
    versionLabel: string;
    messageLimitText1: string;
    messageLimitText2: string;
    messageLimitText3: string;
    messageLimitHeader: string;
    messageCapInfo1: string;
    messageCapInfo2: string;
    caution: string;
    feedbackInvitation: string;
    donateButton: string;
    clearChatButton: string;
    errorText: string;
    donate: string;
    emptyChat: string;
    //ChatInputArea
    messagePlaceholder: string;
    //ChatHeader
    chatHeaderModalTitle: string;
    chatHeaderModalText: string;
    //MessageSeperator
    today: string;
    yesterday: string;
    //TypingAnimation
    isTyping: string;
    //RejectMessage
    rejectMessage: string;
    // Share Dialog
    shareDialogTitle: string;
    shareDialogText: string;
    shareDialogUrl: string;
    shareDialogClipboardText: string;
    shareDialogSuccessMessage: string;
    shareDialogSuccessClipboardMessage: string;
    shareDialogErrorMessage: string;
    shareDialogErrorClipboardMessage: string;
}

const german: translations = {
    //StartScreen
    greeting: "Die mate App 👋",
    initialCatchPhrase: "Starte ein Gespräch. Werde verstanden und erfahre Verbundenheit.",
    yourNamePlaceholder: "Dein Name lautet...",
    yourEmailPlaceholder: "Deine Email lautet...",
    startChatButton: "Gespräch starten",
    continueChatButton: "Gespräch fortsetzen",
    //foormuliere es neett "please provide a valid email"
    provideDetails: "Bitte gib eine gültige Email-Adresse an, um fortzufahren.",
    termsAndConditions1: "Mit dem Starten des Chats stimmst du unseren ",
    termsAndConditions2: " zu.",
    termsAndConditionsLink: "AGB",
    madeIn: "Made in Germany 🇩🇪",
    //ChatScreen
    versionLabel: "Version: ",
    messageLimitText1: "Du kannst maximal ",
    messageLimitText2: " Nachrichten pro Stunde senden. Bitte versuche es um ",
    messageLimitText3: " Uhr noch einmal.",
    messageLimitHeader: "Nachrichtenlimit erreicht",
    messageCapInfo1: "Du kannst pro Stunde maximal ",
    messageCapInfo2: " Nachrichten senden.",
    caution: "Dein mate kann Fehler machen. Bitte überprüfe relevante Informationen. Durch die Nutzung von mate hilfst du uns, das Sprachmodell zu verbessern.",
    feedbackInvitation: "Verbesserungsvorschläge gerne an account@mateawake.com melden.",
    donateButton: "Spenden",
    clearChatButton: "Chat leeren",
    errorText: "Tut mir leid, ich habe gerade ein Problem. Bitte versuche es später noch einmal.",
    donate: "Spenden",
    emptyChat: "Chat leeren",
    //ChatInputArea
    messagePlaceholder: "Nachricht schreiben...",
    //ChatHeader
    chatHeaderModalTitle: "Info",
    chatHeaderModalText: "Dies ist ein Demo-Chat, um die Fähigkeiten des mate zu testen. Frohes Testen!",
    //MessageSeperator
    today: "Heute",
    yesterday: "Gestern",
    //TypingAnimation
    isTyping: "schreibt...",
    //RejectMessage
    rejectMessage: "Deine Nachricht ist zu lang...",
    // Share Dialog
    shareDialogTitle: 'Entdecke \'mate\'',
    shareDialogText: 'Erkunde \'mate\' — wo KI auf persönliches Wachstum trifft. Beginne deine Reise heute!',
    shareDialogUrl: 'https://chat.mateawake.com',
    shareDialogClipboardText: 'Schau dir \'mate\' für eine einzigartige KI-gesteuerte Reise zur persönlichen Entwicklung an: ',
    shareDialogSuccessMessage: 'Danke fürs Teilen!',
    shareDialogSuccessClipboardMessage: 'Link in die Zwischenablage kopiert! Teile ihn mit Freunden, um \'mate\' zu erkunden.',
    shareDialogErrorMessage: 'Teilen nicht möglich. Link wurde in die Zwischenablage kopiert!',
    shareDialogErrorClipboardMessage: 'Link konnte nicht kopiert werden. Versuche es noch einmal!',
};

const english: translations = {
    //StartScreen
    greeting: "The mate App 👋",
    initialCatchPhrase: "Start a conversation. Be understood and experience connection.",
    yourNamePlaceholder: "Your name is...",
    yourEmailPlaceholder: "Your email is...",
    startChatButton: "Start Chat",
    continueChatButton: "Continue Chat",
    provideDetails: "Please provide a valid email to continue.",
    termsAndConditions1: "By starting the chat you agree to our ",
    termsAndConditions2: ".",
    termsAndConditionsLink: "GTC",
    madeIn: "Made in Germany 🇩🇪",
    //ChatScreen
    versionLabel: "Version: ",
    messageLimitText1: "You can send a maximum of ",
    messageLimitText2: " messages per hour. Please try again at ",
    messageLimitText3: " o'clock.",
    messageLimitHeader: "Message limit reached",
    messageCapInfo1: "You can send a maximum of ",
    messageCapInfo2: " messages per hour.",
    caution: '\'mate\' can make mistakes. Please verify relevant information. By using mate, you help us improve the language model.',
    feedbackInvitation: "Feel free to send suggestions to account@mateawake.com.",
    donateButton: "Donate",
    clearChatButton: "Clear Chat",
    errorText: "Sorry, I am having a problem right now. Please try again later.",
    donate: "Donate",
    emptyChat: "Clear Chat",
    //ChatInputArea
    messagePlaceholder: "Write a message...",
    //ChatHeader
    chatHeaderModalTitle: "Info",
    chatHeaderModalText: "This is a demo chat to test the capabilities of mate. Happy testing!",
    //MessageSeperator
    today: "Today",
    yesterday: "Yesterday",
    //TypingAnimation
    isTyping: "typing...",
    //RejectMessage
    rejectMessage: "Your message is too long...",
    // Share Dialog
    shareDialogTitle: 'Discover \'mate\'',
    shareDialogText: 'Explore \'mate\' — where AI meets personal growth. Start your journey today!',
    shareDialogUrl: 'https://chat.mateawake.com',
    shareDialogClipboardText: 'Check out \'mate\' for a unique AI-driven journey to personal growth: ',
    shareDialogSuccessMessage: 'Thanks for sharing!',
    shareDialogSuccessClipboardMessage: 'Link copied to clipboard! Share it with friends to explore \'mate\'.',
    shareDialogErrorMessage: 'Sharing failed. Link copied to clipboard!',
    shareDialogErrorClipboardMessage: 'Failed to copy link. Try again!',
};

const romanian: translations = {
    //StartScreen
    greeting: "Aplicația mate 👋",
    initialCatchPhrase: "Începe o conversație. Fii înțeles și experimentează conexiunea.",
    yourNamePlaceholder: "Numele tău este...",
    yourEmailPlaceholder: "Emailul tău este...",
    startChatButton: "Începe Chatul",
    continueChatButton: "Continuă Chatul",
    provideDetails: "Te rog furnizează un email valid pentru a continua.",
    termsAndConditions1: "Prin începerea chatului ești de acord cu ",
    termsAndConditions2: " noastre.",
    termsAndConditionsLink: "Termenii și condițiile",
    madeIn: "Fabricat în Germania 🇩🇪",
    //ChatScreen
    versionLabel: "Versiune: ",
    messageLimitText1: "Poți trimite un maxim de ",
    messageLimitText2: " mesaje pe oră. Te rog încearcă din nou la ",
    messageLimitText3: " ora.",
    messageLimitHeader: "Limita de mesaje atinsă",
    messageCapInfo1: "Poți trimite un maxim de ",
    messageCapInfo2: " mesaje pe oră.",
    caution: "mate-ul tău poate face greșeli. Te rog verifică informațiile relevante. Folosind mate, ne ajuți să îmbunătățim modelul de limbaj.",
    feedbackInvitation: "Trimite sugestii la account@mateawake.com.",
    donateButton: "Donează",
    clearChatButton: "Șterge Chatul",
    errorText: "Îmi pare rău, am o problemă acum. Te rog încearcă din nou mai târziu.",
    donate: "Donează",
    emptyChat: "Șterge Chatul",
    //ChatInputArea
    messagePlaceholder: "Compune un mesaj...",
    //ChatHeader
    chatHeaderModalTitle: "Info",
    chatHeaderModalText: "Acesta este un chat demo pentru a testa capabilitățile mate. Testare plăcută!",
    //MessageSeperator
    today: "Azi",
    yesterday: "Ieri",
    //TypingAnimation
    isTyping: "scrie...",
    //RejectMessage
    rejectMessage: "Mesajul tău este prea lung...",
    // Share Dialog
    shareDialogTitle: 'Descoperă \'mate\'',
    shareDialogText: 'Explorează \'mate\' — unde IA întâlnește dezvoltarea personală. Începe călătoria ta astăzi!',
    shareDialogUrl: 'https://chat.mateawake.com',
    shareDialogClipboardText: 'Descoperă \'mate\' pentru o călătorie unică condusă de IA în dezvoltarea personală: ',
    shareDialogSuccessMessage: 'Mulțumim pentru partajare!',
    shareDialogSuccessClipboardMessage: 'Link-ul a fost copiat în clipboard! Împărtășește-l cu prietenii pentru a explora \'mate\'.',
    shareDialogErrorMessage: 'Partajarea a eșuat. Link-ul a fost copiat în clipboard!',
    shareDialogErrorClipboardMessage: 'Link-ul nu a putut fi copiat. Încearcă din nou!'
};


export const languages: { [key: string]: translations } = {
    //select language per lcid key
    //german
    "de-DE": german,
    "de-AT": german,
    "de-CH": german,
    "de-LU": german,
    "de": german,
    //english
    "en": english,
    "en-US": english,
    "en-GB": english,
    "en-CA": english,
    "en-AU": english,
    "en-NZ": english,
    "en-001": english,
    "en-150": english,
    "en-AG": english,
    "en-AI": english,
    "en-AS": english,
    "en-AT": english,
    "en-BB": english,
    "en-BE": english,
    "en-BI": english,
    "en-BM": english,
    "en-BS": english,
    "en-BW": english,
    "en-BZ": english,
    "en-CC": english,
    "en-CH": english,
    "en-CK": english,
    "en-CM": english,
    "en-CX": english,
    "en-CY": english,
    "en-DE": english,
    "en-DG": english,
    "en-DK": english,
    "en-DM": english,
    "en-ER": english,
    "en-FI": english,
    "en-FJ": english,
    "en-FK": english,
    "en-FM": english,
    "en-GD": english,
    "en-GG": english,
    "en-GH": english,
    "en-GI": english,
    "en-GM": english,
    "en-GU": english,
    "en-GY": english,
    "en-HK": english,
    "en-IE": english,
    "en-IL": english,
    "en-IM": english,
    "en-IN": english,
    "en-IO": english,
    "en-JE": english,
    "en-JM": english,
    "en-KE": english,
    "en-KI": english,
    "en-KN": english,
    "en-KY": english,
    "en-LC": english,
    "en-LR": english,
    "en-LS": english,
    "en-MG": english,
    "en-MH": english,
    "en-MO": english,
    "en-MP": english,
    "en-MS": english,
    "en-MT": english,
    "en-MU": english,
    "en-MW": english,
    "en-MY": english,
    "en-NA": english,
    "en-NF": english,
    "en-NG": english,
    "en-NL": english,
    "en-NR": english,
    "en-NU": english,
    "en-PG": english,
    "en-PH": english,
    "en-PK": english,
    "en-PN": english,
    "en-PR": english,
    "en-PW": english,
    "en-RW": english,
    "en-SB": english,
    "en-SC": english,
    "en-SD": english,
    "en-SE": english,
    "en-SG": english,
    "en-SH": english,
    "en-SI": english,
    "en-SL": english,
    "en-SS": english,
    "en-SX": english,
    "en-SZ": english,
    "en-TC": english,
    "en-TK": english,
    "en-TO": english,
    "en-TT": english,
    "en-TV": english,
    "en-TZ": english,
    "en-UG": english,
    "en-UM": english,
    "en-US-POSIX": english,
    "en-VC": english,
    "en-VG": english,
    "en-VI": english,
    "en-VU": english,
    "en-WS": english,
    "en-ZA": english,
    "en-ZM": english,
    "en-ZW": english,
    //romanian
    "ro-RO": romanian,
    "ro-MD": romanian,
    "ro": romanian,
}