import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Stack, TextField, Text, PrimaryButton, IconButton } from '@fluentui/react';
import MyChat from './MyChat';
import { StartScreenStyle, PrimaryButtonStyle, Theme, StartScreenTextFieldStyle, StartScreenButtonStyle, fadeInNoMovement } from '../Utilities/MyChatStyles';
import { ChatContext } from '../Utilities/ChatContext';
import { handleEmailChange, handleUsernameChange, openShareLinkDialog } from '../Utilities/Utility';
import useLocalStorage from '../Hooks/useLocalStorage';
import { languages } from '../Utilities/Localizations';
import useWindowSize from '../Hooks/useWindowSize';

export interface StartScreenProps {
    version: string;
    isMobile: boolean;
}

const StartScreen: React.FC<StartScreenProps> = ({ version, isMobile }) => {
    const chatContext = useContext(ChatContext);

    const [showChat, setShowChat] = useState(false);
    const windowSize = useWindowSize();

    const [username, setUsername] = useLocalStorage('username', "");
    const [email, setEmail] = useLocalStorage('email', "");
    const [isValid, setIsValid] = useState(false);
    const [language] = useLocalStorage('language', 'en-US'); // Default to 'en-US' if not set

    // Select the correct translations based on the language
    const translations = languages[language];

    useEffect(() => {
        validateInput();
        //eslint-disable-next-line
    }, [username, email]);

    const handleStartChat = () => {
        // Generate a random email
        const randomCode = Math.floor(100000 + Math.random() * 900000);
        const randomEmail = `${randomCode}@guest.mate`;

        // Update the context state
        setUsername(username || "User");
        setEmail(email || randomEmail);

        setShowChat(true);
    }

    const validateInput = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValid(emailRegex.test(email) && username.trim() !== '');
    };

    const fadeInStyle = {
        animation: `${fadeInNoMovement} 1s ease forwards`
    };

    if (showChat) {
        return (
            <MyChat isMobile_={isMobile} version_={version} />
        )
    }

    return (
        <>
            <Stack styles={StartScreenStyle} horizontalAlign='center' verticalAlign="start">
                <Stack horizontal horizontalAlign={isMobile ? "space-between" : "end"} tokens={{ childrenGap: 14 }} styles={{ root: { width: windowSize.width - 50 } }}>
                    <Stack.Item>
                        <IconButton iconProps={{ iconName: "Share" }} styles={StartScreenButtonStyle} onClick={() => openShareLinkDialog(translations, toast)} />
                    </Stack.Item>
                </Stack>
                <Stack verticalAlign='space-between' styles={{ root: { maxWidth: isMobile ? "80%" : 580, marginTop: isMobile ? 20 : 50, marginBottom: 40 } }} tokens={{ childrenGap: isMobile ? 50 : 130 }}>
                    <Stack horizontalAlign='center' verticalAlign='center'>
                        <Text variant='xLarge' styles={{ root: { color: `black`, fontWeight: Theme.fonts.xLarge.fontWeight, fontFamily: Theme.fonts.xLarge.fontFamily } }}>
                            {translations.greeting}
                        </Text>
                    </Stack>
                    <Stack>
                        <Text variant="xxLargePlus" block styles={{ root: { color: `black`, textAlign: "center", fontWeight: Theme.fonts.xLarge.fontWeight, fontFamily: Theme.fonts.xLarge.fontFamily } }}>
                            {translations.initialCatchPhrase}
                        </Text>
                    </Stack>
                    <Stack
                        tokens={{ childrenGap: 8 }}
                        styles={{ root: { padding: isMobile ? "0" : "0 60px 0 60px" } }}
                    >
                        <Stack.Item>
                            <TextField
                                styles={StartScreenTextFieldStyle}
                                value={username}
                                onChange={(event, newValue) => handleUsernameChange(event, setUsername, newValue)}
                                placeholder={translations.yourNamePlaceholder}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                styles={StartScreenTextFieldStyle}
                                value={email}
                                onChange={(event, newValue) => handleEmailChange(event, setEmail, newValue)}
                                placeholder={translations.yourEmailPlaceholder}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <PrimaryButton
                                styles={PrimaryButtonStyle}
                                disabled={!isValid}
                                text={chatContext.state.messages.length > 0 && username.length > 0 ? translations.continueChatButton : translations.startChatButton}
                                onClick={handleStartChat}
                            />
                        </Stack.Item>
                        {(username !== "" && email.length >= 3 && !isValid) && <Stack.Item align='center' style={fadeInStyle}>
                            <Text variant="medium" styles={{ root: { textAlign: "center", fontWeight: Theme.fonts.xLarge.fontWeight, color: `black`, fontFamily: Theme.fonts.xLarge.fontFamily } }}>
                                {translations.provideDetails}
                            </Text>
                        </Stack.Item>}
                    </Stack>
                </Stack>

                <Stack verticalAlign='end' styles={{ root: { position: 'fixed', bottom: 0, left: 0, right: 0, padding: '5px 0px 10px 0px', backgroundColor: 'white', borderTop: '1px solid lightgrey' } }}>
                    <Stack.Item align="center">
                        <Text styles={{ root: { color: `black`, fontWeight: Theme.fonts.xLarge.fontWeight, fontFamily: Theme.fonts.xLarge.fontFamily } }} variant={"small"}>
                            {translations.termsAndConditions1}
                            <a href="http://app.mateawake.com" target="_blank" rel="noopener noreferrer" style={{ color: Theme.palette.themeTertiary, fontFamily: Theme.fonts.xLarge.fontFamily }}>
                                {translations.termsAndConditionsLink}
                            </a>
                            {translations.termsAndConditions2}
                        </Text>
                    </Stack.Item>

                    <Stack.Item align='center'>
                        <Text variant="xSmall" styles={{ root: { textAlign: "center", fontWeight: Theme.fonts.xLarge.fontWeight, color: `black`, fontFamily: Theme.fonts.xLarge.fontFamily } }}>
                            {translations.madeIn}
                        </Text>
                    </Stack.Item>
                </Stack>


            </Stack>
        </>
    );
}

export default StartScreen;
