import { IconButton, Stack, Text } from "@fluentui/react";
import React, { Dispatch, SetStateAction } from "react";
import { AvatarButtonStyle, ChatHeaderMsgButtonStyle, ChatHeaderStyle, HeaderTextStyle, ImageStyle } from "../Utilities/MyChatStyles";
import { openLink, openModal } from "../Utilities/Utility";
import useLocalStorage from "../Hooks/useLocalStorage";
import { languages } from "../Utilities/Localizations";
import TypingAnimation from "./TypingAnimation";

export interface ChatHeaderProps {
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    setModalContent: Dispatch<SetStateAction<{ header: string; text: string; }>>;
    isDisabled: boolean;
    coachIsTyping: boolean;
    messagesRemaining: number;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ setModalOpen, setModalContent, isDisabled, coachIsTyping, messagesRemaining }) => {
    const [language] = useLocalStorage('language', 'en-US'); // Default to 'en-US' if not set
    const translations = languages[language];
    return (
        <>
            <Stack className="HeaderStack" styles={ChatHeaderStyle}>
                <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="space-between" verticalAlign="center" styles={{ root: { border: "" } }}>
                    <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { marginTop: "-4px" } }} horizontalAlign="space-between" verticalAlign="start">
                        <Stack.Item>
                            <button
                                disabled={isDisabled}
                                onClick={() => openModal(
                                    translations.chatHeaderModalTitle,
                                    translations.chatHeaderModalText,
                                    setModalContent,
                                    setModalOpen
                                )}
                                style={AvatarButtonStyle}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/Mate Awake Logo 1.png`}
                                    alt="Chat Avatar"
                                    style={ImageStyle} // Apply the ImageStyle here
                                />
                            </button>
                        </Stack.Item>
                        <Stack tokens={{ childrenGap: 4 }} styles={{ root: { border: "" } }} verticalAlign="start">
                            <Stack.Item styles={{ root: { border: "" } }}>
                                <Text
                                    styles={HeaderTextStyle}
                                    variant='xLarge'
                                    onClick={() => openLink("https://www.instagram.com/mate_awake/")}>
                                    @mate_awake</Text>
                            </Stack.Item>
                            {coachIsTyping &&
                                <Stack.Item>
                                    <TypingAnimation />
                                </Stack.Item>}
                        </Stack>
                    </Stack>
                    <Stack.Item align="start">
                        <Stack horizontal tokens={{ childrenGap: 2 }} styles={{ root: { marginRight: 10 } }} horizontalAlign="space-between" verticalAlign="center">
                            {messagesRemaining <= 50 && <><Stack.Item>
                                <IconButton disabled={true} iconProps={{ iconName: "Mail" }} styles={ChatHeaderMsgButtonStyle} />
                            </Stack.Item><Stack.Item styles={{
                                root: {
                                    marginTop: "-5px"
                                }
                            }}>
                                    <Text
                                        styles={HeaderTextStyle}
                                        variant='xLarge'>{messagesRemaining}</Text>
                                </Stack.Item></>}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
}

export default ChatHeader;