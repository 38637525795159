//ignore all warnings in this file
/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { calculateNextAvailableTime, calculateRemainingMessages, callGenerateImpulseApi, callOpenAiApi, checkMessageLimit, cleanMessage, clearMessages, closeModal, createMessage, createPostData, createUserResponse, ensureMyMaxMessages, fetchEnvVariable, fetchMaxMessages, fetchMessageCap, getGreetingWithTime, getImpulse, openLink, openModal, pushCoachResponseToChat, storeUserInformation, updateApiMessages, updateMessages } from "../Utilities/Utility";
import { IconButton, Modal, Stack, Text, css } from "@fluentui/react";
import ChatHeader from "../ChatComponents/ChatHeader";
import ChatInputArea from "../ChatComponents/ChatInputArea";
import ChatMessageArea from "../ChatComponents/ChatMessageArea";
import { ChatHeaderButtonStyle, ChatHeaderWrapperStyle, ChatStyle, ModalContentStyle, ModalOverlayStyle, Theme } from "../Utilities/MyChatStyles";
import { Rating } from '../ChatComponents/FeedbackActions';
import { ChatContext, ChatContextProps } from '../Utilities/ChatContext';
import useLocalStorage from '../Hooks/useLocalStorage';
import { Zoom, cssTransition, toast } from 'react-toastify';
import { languages } from '../Utilities/Localizations';

export type MyMessageDirection = "incoming" | "outgoing" | 0 | 1;

export type MyMessageType = "html" | "text" | "image" | "custom";

export type MyUserStatus = "available" | "unavailable" | "away" | "dnd" | "invisible" | "eager";

export type MyMessageProps = {
    type: MyMessageType;
    direction: MyMessageDirection;
    message: string;
    timeStamp: string;
    date: string;
    hasSeperator?: boolean;
    dateSent: Date;
    id?: string;
    rating?: Rating;
}

export interface ChatProps {
    version_: string;
    isMobile_: boolean;
}

const MyChat: React.FC<ChatProps> = ({ isMobile_, version_ }) => {
    const [reset, setReset] = useState(false);
    const chatContext = useContext(ChatContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState<{ header: string, text: string }>({ header: '', text: '' });
    const [nextAvailableTime, setNextAvailableTime] = useState<Date | null>(null);

    const [username, setUsername] = useLocalStorage('username', "");
    const [email] = useLocalStorage('email', '');
    const [language] = useLocalStorage('language', 'en-US'); // Default to 'en-US' if not set
    const translations = languages[language];

    const [coachIsTyping, setCoachIsTyping] = useState(false);
    const [userHasSentMsg, setUserHasSentMsg] = useState(false);

    const masMessagesFallback = 18;
    const [maxMessages, setMaxMessages] = useState(masMessagesFallback); // max number of messages to keep in memory, default: 24

    const messageCapFallback = 100;
    const [messageCap, setMessageCap] = useState(messageCapFallback); // max number of messages that can be sent per hour, default: 5
    const [remainingMessages, setRemainingMessages] = useState(messageCap);
    const [messages, setMessages] = useState(chatContext.state.messages || [] as MyMessageProps[]);
    const [apiMessages, setApiMessages] = useState(chatContext.state.apiMessages || [] as MyMessageProps[]);

    const [date] = useState(new Date().toLocaleDateString());

    const updateRemainingMessages = useCallback(() => {
        const recentOutgoingMessages = apiMessages.filter(
            msg => new Date(msg.dateSent).getTime() > Date.now() - 3600000 && msg.direction === "outgoing"
        );
        const newRemainingMessages = messageCap - recentOutgoingMessages.length;
        setRemainingMessages(newRemainingMessages);
        // Check message limit inside the centralized update function
        if (checkMessageLimit(chatContext, messageCap, setModalContent, setModalOpen, setNextAvailableTime, translations)) {
            setRemainingMessages(0);
        }
    }, [apiMessages, messageCap, translations, setModalContent, setModalOpen, setNextAvailableTime]);

    // USEEFFECT ON EVERY RENDER WHEN MESSAGES CHANGE
    useEffect(() => {
        updateLocalStorageChatState();
        updateRemainingMessages();
    }, [messages, apiMessages, updateRemainingMessages]);

    // USEEFFECT ON FIRST RENDER
    useEffect(() => {
        setMessages(chatContext.state.messages);
        setApiMessages(chatContext.state.apiMessages);
        setRemainingMessages(calculateRemainingMessages(chatContext.state.apiMessages.filter(msg => msg.direction === "outgoing"), messageCap));
        setNextAvailableTime(calculateNextAvailableTime(chatContext.state.apiMessages.filter(msg => msg.direction === "outgoing"), messageCap));
        updateRemainingMessages();
        if (checkMessageLimit(chatContext, messageCap, setModalContent, setModalOpen, setNextAvailableTime, translations)) { setRemainingMessages(0); }

        // Sofort ausgeführte asynchrone Funktion im useEffect
        (async () => {
            await fetchMaxMessages(setMaxMessages, masMessagesFallback);
            //if user has a special name, give him 100 messages
            if (username === "Olga") {
                setMessageCap(100);
            } else {
                await fetchMessageCap(setMessageCap, messageCapFallback);
            }
            // if there are no messages in the chat, send the initial message
            if (messages.length === 0) {
                setCoachIsTyping(true);
            }
            else if (messages.length >= 4) {
                fetchImpulse();
            }
            setTimeout(() => {
                if (messages.length === 0) {
                    const initialCoachMsg = createMessage(getGreetingWithTime(username, language.substring(0, 2)), "text", "incoming", messages, date);
                    setMessages([...messages, initialCoachMsg]);
                    setApiMessages([...apiMessages, initialCoachMsg]);
                }
                setCoachIsTyping(false);
                setUserHasSentMsg(false);
                // }
            }, 3000);
        })();
    }, [reset]);

    // USEEFFECT ON UNMOUNT OR STATE CHANGE
    useEffect(() => {
        // This hook will now act as the lifecycle event for component unmount
        return () => {
            // Perform the local storage update here
            localStorage.setItem('chatState', JSON.stringify(chatContext.state));
        };
    }, [chatContext.state]); // The dependency ensures this runs only when the component unmounts or the state changes significantly


    const updateLocalStorageChatState = () => {
        // Get the saved state
        const savedState: ChatContextProps = JSON.parse(localStorage.getItem('chatState') || '{}');

        // Merge current messages with saved messages
        const mergedMessages = messages.map(message => {
            const savedMessage = savedState.messages?.find(savedMessage => savedMessage.id === message.id);
            return { ...message, ...savedMessage };
        });

        const newContextState = {
            messages: mergedMessages,
            apiMessages: apiMessages as MyMessageProps[],
        } as ChatContextProps;

        // Update context
        chatContext.updateState(newContextState);

        // Update local storage
        localStorage.setItem('chatState', JSON.stringify(newContextState));
    }

    const handleSend = async (message: string) => {
        if (checkMessageLimit(chatContext, messageCap, setModalContent, setModalOpen, setNextAvailableTime, translations)) { setRemainingMessages(0); return; }


        setUserHasSentMsg(true);
        const cleanedMessage = cleanMessage(message);
        const userResponse = createUserResponse(cleanedMessage, messages);
        const newMessages = updateMessages(userResponse, messages, setMessages);
        const newApiMessages = updateApiMessages(userResponse, apiMessages, maxMessages, setApiMessages);
        const postData = createPostData(newApiMessages, userResponse, language);
        const data = await callOpenAiApi(postData);

        // Generate a response with a random delay
        generateResponseWithDelay(data, pushCoachResponseToChat);

        // Store user information after sending message
        await storeUserInformation(newMessages, username, email);
    };

    const generateResponseWithDelay = (data: any, pushCoachResponseToChat: (message: any, messages: MyMessageProps[], maxMessages: number, setCoachIsTyping: React.Dispatch<React.SetStateAction<boolean>>, setMessages: React.Dispatch<React.SetStateAction<MyMessageProps[]>>, setApiMessages: React.Dispatch<React.SetStateAction<MyMessageProps[]>>, setUserHasSentMsg: React.Dispatch<React.SetStateAction<boolean>>) => void) => {
        // Generate a random delay between 3 and 7 seconds (2000 - 500 milliseconds)
        const randomDelay = Math.floor(Math.random() * (2000 - 500 + 1)) + 500;
        setTimeout(() => {
            if (data) {
                pushCoachResponseToChat(data.messageContent, messages, maxMessages, setCoachIsTyping, setMessages, setApiMessages, setUserHasSentMsg);
            } else {
                pushCoachResponseToChat("Tut mir leid, ich habe gerade ein Problem. Bitte versuche es später noch einmal.", messages, maxMessages, setCoachIsTyping, setMessages, setApiMessages, setUserHasSentMsg);
            }
            // fakeMessages();
        }, randomDelay);
    }

    const fetchImpulse = async () => {
        const impulseResponse = await callGenerateImpulseApi(apiMessages.slice(-10), language.substring(0, 2));
        let impulseMessage: string;

        if (impulseResponse === null || impulseResponse === "error") {
            impulseMessage = getImpulse(language.substring(0, 2)); // Using fallback
        } else {
            impulseMessage = impulseResponse;
        }

        toast.info(impulseMessage, {    
            closeButton: false,
            icon(props) {
                return <img src={`${process.env.PUBLIC_URL}/android-chrome-384x384.png`} alt="Chat Avatar" style={{ width: "40px", height: "40px", borderRadius: "50%", marginLeft: "-15px" }} />;
            },
            delay: 3500,
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            transition: Zoom,
            onClick: () => {
                let msg;
                if (impulseResponse === null || impulseResponse === "error") {
                    msg = createMessage(impulseMessage!, "text", "incoming", messages, date);
                } else {
                    msg = createMessage(impulseResponse, "text", "incoming", messages, date);
                }
                setMessages([...messages, msg]);
                setApiMessages([...apiMessages, msg]);
            },
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            // Custom style for this specific toast
            style: {
                backgroundColor: Theme.palette.neutralLighter,
                color: Theme.palette.black,
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: Theme.fonts.xLarge.fontFamily,
                borderRadius: "10px",
                padding: "10px 10px 10px 20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            },
        });

    };

    return (
        <>
            <Modal isOpen={modalOpen} onDismiss={() => closeModal(setModalOpen)} isBlocking={false} styles={ModalOverlayStyle}>
                <Stack horizontalAlign='space-between' styles={ModalContentStyle} tokens={{ childrenGap: 20 }}>
                    <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }} >
                        <Text variant='xxLarge'>{modalContent.header}</Text>
                        <IconButton onClick={() => closeModal(setModalOpen)} styles={ChatHeaderButtonStyle} iconProps={{ iconName: 'ChromeClose' }}></IconButton>
                    </Stack>
                    <Stack.Item>
                        <Text variant='large' styles={{ root: { fontWeight: 700 } }}>{translations.versionLabel}{version_}</Text>
                    </Stack.Item>
                    <Stack.Item />
                    <Text variant='large' >{modalContent.text}</Text>
                    {remainingMessages === 0 && <Text variant='large'>{translations.messageCapInfo1 + messageCap + translations.messageCapInfo2}</Text>}
                    <Text variant="large">{translations.caution}</Text>
                    <Text variant='large' >{translations.feedbackInvitation}</Text>
                    <Text variant='large' >
                        <>
                            {translations.termsAndConditions1}
                            <a href="http://app.mateawake.com" target="_blank" rel="noopener noreferrer" style={{ color: Theme.palette.themeTertiary, fontFamily: Theme.fonts.xLarge.fontFamily }}>
                                {translations.termsAndConditionsLink}
                            </a>
                            {translations.termsAndConditions2}
                        </>
                    </Text>
                    <Stack.Item />
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                        {false && <Stack.Item>
                            <IconButton iconProps={{ iconName: "Money" }} text={translations.donate} styles={ChatHeaderButtonStyle} onClick={() => openLink("https://www.paypal.com/pools/c/906HNmZ09N")} />
                        </Stack.Item>}
                        <Stack.Item align="center">
                            {/** Add a text using translations.clearChat */}
                            <Text variant='large'>{translations.emptyChat}</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <IconButton iconProps={{ iconName: "Delete" }} text={translations.emptyChat} styles={ChatHeaderButtonStyle} onClick={() => {clearMessages(messages, setMessages, setApiMessages, updateLocalStorageChatState, setReset, setRemainingMessages, messageCap, username); closeModal;}} />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Modal>
            <Stack styles={ChatStyle}>
                <div style={ChatHeaderWrapperStyle}>
                    <ChatHeader
                        messagesRemaining={remainingMessages}
                        coachIsTyping={coachIsTyping}
                        isDisabled={coachIsTyping || userHasSentMsg}
                        setModalOpen={setModalOpen}
                        setModalContent={setModalContent}
                    />
                </div>
                <ChatMessageArea messages={messages} version={version_} isMobile={isMobile_} />
                <ChatInputArea
                    onSendMessage={handleSend}
                    inputDisabled={coachIsTyping || userHasSentMsg || !!(nextAvailableTime && new Date() < nextAvailableTime)}
                    coachIsTyping={coachIsTyping}
                    limitReached={!!(nextAvailableTime && new Date() < nextAvailableTime)}
                    nextAvailableTime={nextAvailableTime}
                    setModalContent={setModalContent}
                    setModalOpen={setModalOpen}
                    messageCap={messageCap}
                />
            </Stack>
        </>
    );
}

export default MyChat;
